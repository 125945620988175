import pino, { Logger } from 'pino'

import logLevelData from './log-level'

// https://github.com/tatleung/next-with-pino
const logLevels = new Map<string, string>(Object.entries(logLevelData))

export function getLogLevel(logger: string): string {
  return logLevels.get(logger) || logLevels.get('*') || 'info'
}

export function getLogger(name: string, headers?: Headers): Logger {
  const base: { [key: string]: any } = {}
  const redact: string[] = []

  const correlationId = headers?.get('X-Correlation-Id')
  correlationId && (base['x-correlation-id'] = correlationId)

  process.env['DOCKER_IMAGE'] && (base.image = process.env['DOCKER_IMAGE'])

  if (process.env['NODE_ENV'] !== 'development') {
    redact.push('body.password')
  }

  return pino({
    name,
    level: getLogLevel(name),
    base,
    redact,
  })
}
