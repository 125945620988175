const logLevelData = {
  '*': 'silent',
  home: 'info',
  api: 'debug',
  analytics: 'info',
  bigcommerce: 'info',
  contentful: 'info',
}

export default logLevelData
